/** @jsxImportSource @emotion/react */
import { EuiProvider } from '@elastic/eui';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { EuiThemeColorMode } from '@elastic/eui';
import AppStorage from '../utils/storages/appStorage';
import { Global } from '@emotion/react';
import globalStyles from './theme.style';
import { EuiThemeModifications } from '@elastic/eui/src/services/theme/types';

const customTheme: EuiThemeModifications = {
    colors: {
        DARK: {
            primary: '#242943',
            accent: '#9dc8c2',
            success: '#2A8C46',
            warning: '#EEC344',
            danger: '#AC112B',
            link: '#357ABE',
            accentText: '#FFFFFF',
        },
        LIGHT: {
            primary: '#242943',
            accent: '#9dc8c2',
            success: '#2A8C46',
            warning: '#EEC344',
            danger: '#AC112B',
            link: '#357ABE',
            accentText: '#000000',
        },
    },
    border:{
        radius:{
            medium: '12px',
        }
    },
    font: {
        family: 'Montserrat',
        defaultUnits: 'rem',
        scale: {
            xxxs: 0.5625,
            xxs: 0.6875,
            xs: 0.75,
            s: 0.875,
            m: 1,
            l: 1.375,
            xl: 2.125,
            xxl: 3,
        },
    },
};

const FALLBACK_THEME: EuiThemeColorMode = 'light';
const STORAGE_NAME: string = 'theme';
const ThemeContext = createContext<{
    colorMode: EuiThemeColorMode;
    setColorMode: (colorMode: EuiThemeColorMode) => void;
}>({
    colorMode: FALLBACK_THEME,
    setColorMode: () => null,
});
export const useThemeContext = () => {
    return useContext(ThemeContext);
};
export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const [colorMode, setColorMode] = useState<EuiThemeColorMode>(
        (AppStorage.getItem(STORAGE_NAME) as EuiThemeColorMode) ??
            FALLBACK_THEME
    );
    const changeTheme = (colorMode: EuiThemeColorMode) => {
        setColorMode(colorMode);
        AppStorage.setItem(STORAGE_NAME, colorMode);
    };
    return (
        <ThemeContext.Provider value={{ colorMode, setColorMode: changeTheme }}>
            <EuiProvider modify={customTheme} colorMode={colorMode}>
                <Global styles={globalStyles} />
                {children}
            </EuiProvider>
        </ThemeContext.Provider>
    );
};
