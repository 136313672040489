import {createContext, ReactNode, useContext, useState} from "react";
import {EuiGlobalToastList} from "@elastic/eui";
import {Toast} from "@elastic/eui/src/components/toast/global_toast_list";

let toastId = 0;

const ToastContext = createContext<{
    toasts: Array<Toast>,
    add: (addableToast: Omit<Toast, "id">) => void;
    remove: (removableToast: Toast) => void;
}>({
    toasts: [],
    add: () => null,
    remove: () => null,
})

export const useToastContext = () => {
    return useContext(ToastContext);
}
export const ToastProvider = ({children}: { children: ReactNode }) => {
    const [toasts, setToasts] = useState<Array<Toast>>([]);

    const remove = (removableToast: Toast): void => {
        setToasts(toasts.filter((toast) => toast.id !== removableToast.id));
    }
    const add = (addableToast: Omit<Toast, "id">): void => setToasts(toasts => [...toasts, {
        ...addableToast,
        id: `toast-${toastId++}`
    }])
    return (
        <ToastContext.Provider value={{toasts, add, remove}}>
            {children}
            <EuiGlobalToastList toasts={toasts} dismissToast={remove} toastLifeTimeMs={6000}/>
        </ToastContext.Provider>
    );
}