/** @jsxImportSource @emotion/react */

import Header from '../components/header';
import {Outlet} from 'react-router-dom';
import {EuiFlexGroup, EuiFlexItem} from '@elastic/eui';
import Footer from '../components/footer';
import {css} from "@emotion/react";

const BaseLayout = () => {
    const boxStyle = css` text-align: center`;
    return (
        <EuiFlexGroup css={boxStyle} alignItems={"center"} justifyContent={"center"} gutterSize={'none'}
                      style={{minHeight: '100vh'}}>
            <EuiFlexItem>
                <div style={{paddingBottom: '2rem'}}>
                    <Outlet/>
                </div>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default BaseLayout;
