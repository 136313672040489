import HU from 'src/languages/hu-HU.json';
import GB from 'src/languages/en-GB.json';

import React, { createContext, ReactNode, useContext, useState } from 'react';
import AppStorage from 'src/utils/storages/appStorage';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { LocaleList } from 'src/utils/enums/localeList';
import hu from 'src/assets/img/flags/hu.svg';
import gb from 'src/assets/img/flags/gb.svg';

const FALLBACK_LANGUAGE: LocaleList = LocaleList.HU;
const STORAGE_NAME: string = 'locale';

const languages: {
    [key in LocaleList]:
        | Record<string, string>
        | Record<string, MessageFormatElement[]>
        | undefined;
} = {
    [LocaleList.HU]: HU,
    [LocaleList.GB]: GB,
};

const flags: { [key in LocaleList]: string } = {
    [LocaleList.HU]: hu,
    [LocaleList.GB]: gb,
};

const LocaleContext = createContext<{
    currentLocale: LocaleList;
    flags: { [key in LocaleList]: string };
    setLocale: (locale: LocaleList) => void;
}>({
    currentLocale: FALLBACK_LANGUAGE,
    flags: flags,
    setLocale: () => null,
});
export const useLocaleContext = () => {
    return useContext(LocaleContext);
};

export const LocaleProvider = ({ children }: { children: ReactNode }) => {
    const [locale, setLocale] = useState<LocaleList>(
        (AppStorage.getItem(STORAGE_NAME) as LocaleList) ?? FALLBACK_LANGUAGE
    );

    const changeLocale = (locale: LocaleList) => {
        setLocale(locale);
        AppStorage.setItem(STORAGE_NAME, locale);
    };

    return (
        <LocaleContext.Provider
            value={{ currentLocale: locale, flags, setLocale: changeLocale }}
        >
            <IntlProvider locale={locale} messages={languages[locale]}>
                {children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
};
