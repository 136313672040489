import {css} from "@emotion/react";

import MontserratThin from "../assets/fonts/Montserrat/Montserrat-Thin.ttf";
import MontserratThinItalic from "../assets/fonts/Montserrat/Montserrat-ThinItalic.ttf";
import MontserratExtraLight from "../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf";
import MontserratExtraLightItalic from "../assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf";
import MontserratLight from "../assets/fonts/Montserrat/Montserrat-Light.ttf";
import MontserratLightItalic from "../assets/fonts/Montserrat/Montserrat-LightItalic.ttf";
import MontserratRegular from "../assets/fonts/Montserrat/Montserrat-Regular.ttf";
import MontserratRegularItalic from "../assets/fonts/Montserrat/Montserrat-Italic.ttf";
import MontserratMedium from "../assets/fonts/Montserrat/Montserrat-Medium.ttf";
import MontserratMediumItalic from "../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf";
import MontserratSemiBold from "../assets/fonts/Montserrat/Montserrat-SemiBold.ttf";
import MontserratSemiBoldItalic from "../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf";
import MontserratBold from "../assets/fonts/Montserrat/Montserrat-Bold.ttf";
import MontserratBoldItalic from "../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf";
import MontserratBlack from "../assets/fonts/Montserrat/Montserrat-Black.ttf";
import MontserratBlackItalic from "../assets/fonts/Montserrat/Montserrat-BlackItalic.ttf";

import FutureTense from "../assets/fonts/Future-Tense.ttf";

const globalStyles = css`
    @font-face {
        font-family: 'Future Tense';
        src: url(${FutureTense}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratBlackItalic}) format('truetype');
        font-weight: 900;
        font-style: italic;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratBlack}) format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratBoldItalic}) format('truetype');
        font-weight: 700;
        font-style: italic;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratSemiBoldItalic}) format('truetype');
        font-weight: 600;
        font-style: italic;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratSemiBold}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratMediumItalic}) format('truetype');
        font-weight: 500;
        font-style: italic;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratMedium}) format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratRegularItalic}) format('truetype');
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratLightItalic}) format('truetype');
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratLight}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratExtraLightItalic}) format('truetype');
        font-weight: 200;
        font-style: italic;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratExtraLight}) format('truetype');
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratThinItalic}) format('truetype');
        font-weight: 100;
        font-style: italic;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratThin}) format('truetype');
        font-weight: 100;
        font-style: normal;
    }
`;

export default globalStyles;