class CookieStorage implements Storage {
    length: number = 0;

    [key: string]: any;

    constructor() {
        this.updateLength();
    }

    private updateLength(): void {
        this.length = document.cookie.split(';').length;
    }

    clear(): void {
        const cookies = document.cookie.split(';');
        cookies.forEach((cookie) => {
            const key = cookie.split('=')[0].trim();
            this.removeItem(key);
        });
    }

    getItem(key: string): string | null {
        const name = `${key}=`;
        const ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    removeItem(key: string): void {
        document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        this.updateLength();
    }

    key(index: number): string | null {
        const cookies = document.cookie.split(';');
        if (index >= 0 && index < cookies.length) {
            const key = cookies[index].split('=')[0].trim();
            return key;
        }
        return null;
    }

    setItem(key: string, value: string): void {
        document.cookie = `${key}=${encodeURIComponent(value)}; path=/`;
        this.updateLength();
    }
}

export default CookieStorage;
