import CookieStorage from './cookieStorage';

enum StorageTypes {
    CookieStorage,
    LocalStorage,
}

class AppStorage {
    private storage: Storage;

    constructor(type: StorageTypes = StorageTypes.LocalStorage) {
        switch (type) {
            case StorageTypes.LocalStorage:
                this.storage = localStorage;
                break;
            case StorageTypes.CookieStorage:
                this.storage = new CookieStorage();
                break;
        }
    }

    [key: string]: any;

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string | null {
        return this.storage.getItem(key);
    }

    key(index: number): string | null {
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, value);
    }
}

const instance = new AppStorage();
export default instance;
