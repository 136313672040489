import React from 'react';
import { LocaleProvider } from './providers/locale';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './providers/theme';
import { ToastProvider } from './providers/toast';
import BaseLayout from './layouts/base';
import Home from "./pages/home";

function App() {
    return (
        <LocaleProvider>
            <ThemeProvider>
                <ToastProvider>
                        <Routes>
                            <Route element={<BaseLayout />}>
                                <Route path={'*'} element={<Home />}></Route>
                            </Route>
                        </Routes>
                </ToastProvider>
            </ThemeProvider>
        </LocaleProvider>
    );
}

export default App;
