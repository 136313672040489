import {EuiFlexGroup, EuiImage, EuiTitle} from "@elastic/eui";
import logo_white from "../assets/logo/logo_white_url.png";
import logo_black from "../assets/logo/logo_black_url.png";
import {useThemeContext} from "../providers/theme";
import {css} from "@emotion/react";

const Home = () => {
    const theme = useThemeContext();
    const textStyle = css`
        font-family: 'Future Tense';
        font-size: 36px;
    `;
    return (
        <EuiFlexGroup direction={"column"} gutterSize={"xl"}>
            <EuiImage
                alt={'Logo'}
                src={
                    theme.colorMode === 'dark' ? logo_white : logo_black
                }
                style={{height: '350px'}}
            />
            <EuiTitle css={textStyle}>
                <h1>Site under construction</h1>
            </EuiTitle>
        </EuiFlexGroup>
    )
}
export default Home;